<template>
  <div class="d-lg-flex no-right-sidebar" id="main-wraper">
    <div class="page-wrapper">
      <div class="page-content">
        <div class="row mb-4 justify-content-center">
          <div class="col-lg-7 col-sm-12">
            <div class="row align-items-center text-dark">
              <div class="col-md-6 text-md-left mb-2">
                <h2 class="page-title mb-0">{{ $t("label.Profile") }}</h2>
              </div>
            </div>
            <div class="card card-body content-card mb-4 rounded-l border-0 shadow-sm">
              <ul
                class="nav nav-pills mb-3 py-2 tab-style-1 bg-light justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active"
                    id="profile-tab"
                    data-toggle="pill"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="true"
                    >{{ $t("label.My Profile") }}</a
                  >
                </li>
                <li class="nav-item" role="presentation" @click="getOperatingArea">
                  <a
                    class="nav-link"
                    id="perating_area-tab"
                    data-toggle="pill"
                    href="#perating_area"
                    role="tab"
                    aria-controls="perating_area"
                    aria-selected="false"
                    >{{ $t("label.Operating Area") }}</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="images-tab"
                    data-toggle="pill"
                    href="#images"
                    role="tab"
                    aria-controls="images"
                    aria-selected="false"
                    >{{ $t("label.company_images") }}</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <!-- <div class="p-0 border-0">
                      <img
                        src="images/cover-img.jpg"
                        class="card-img-top rounded-l cover-img"
                        alt=""
                      />
                    </div> -->
                  <form class="form-style">
                    <Form class="form-style floating-form" @submit="update()">
                      <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-5 mb-4 text-center">
                          <div class="mb-3">
                            <img
                              :src="
                                detail.avatar && detail.avatar != null
                                  ? detail.avatar
                                  : '/images/profile.svg'
                              "
                              class="rounded-circle border border-light"
                              style="border-width: 3px !important"
                              alt="image thumb"
                              id="preview"
                              width="130"
                              height="130"
                            />
                          </div>
                          <button
                            type="button"
                            class="btn px-3 upload-img-merge-btn btn-secondary w-75 text-capitalize font-bold"
                            style="color: #00bb59"
                            for="pickFile"
                          >
                            {{ $t("label.Change photo") }}
                            <input
                              type="file"
                              class="btn upload-img"
                              id="pickFile"
                              @change="selectedFile($event, 'profile')"
                            />
                          </button>
                        </div>
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-md-12 col-sm-12">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="fname">{{ $t("label.Name") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="text"
                                  id="fname"
                                  name="fname"
                                  v-model="detail.name"
                                  class="form-control filled"
                                  rules="required:name"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage name="fname" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="email">{{ $t("label.Email") }}</label>
                                <Field
                                  type="email"
                                  id="email"
                                  name="email"
                                  v-model="detail.email"
                                  class="form-control filled"
                                  rules="email"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage name="email" class="validation-msg" />
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="phone">{{ $t("label.Phone") }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="text"
                                  id="phone"
                                  name="phone"
                                  v-model="detail.phone_number"
                                  class="form-control filled"
                                  rules="required:phone number|phone"
                                  :validateOnInput="true"
                                  ><PhoneCode
                                    id="mobile"
                                    placeholder="Mobile Number"
                                    v-model:countryCode="detail.country_code"
                                    v-model="detail.phone_number"
                                    v-model:dialCode="detail.dial_code"
                                    v-bind="field"
                                  />
                                </Field>
                              </div>
                              <ErrorMessage name="phone" class="validation-msg" />
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="com_registration_no">{{
                                  $t("label.Company Registration Number")
                                }}</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="text"
                                  id="com_registration_no"
                                  name="com_registration_no"
                                  v-model="detail.com_registration_no"
                                  class="form-control filled"
                                  rules="required:Company Registration Number"
                                  :validateOnInput="true"
                                />
                              </div>
                              <ErrorMessage
                                name="com_registration_no"
                                class="validation-msg"
                              />
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label>{{ $t("label.Company Details") }}</label>
                                <span class="text-danger">*</span>
                                <textarea
                                  id="about"
                                  name="about"
                                  class="form-control filled"
                                  style="height: 70px"
                                  v-model="detail.about"
                                  cols="30"
                                  rows="4"
                                  rules="required:Company Detail"
                                  :validateOnInput="true"
                                ></textarea>
                              </div>
                              <ErrorMessage name="about" class="validation-msg" />
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label for="city"
                                  ><i class="bx bxs-map"></i
                                  >{{ $t("label.Work Address") }}</label
                                >
                                <vue-google-autocomplete
                                  ref="address"
                                  id="map"
                                  :classname="
                                    detail.address
                                      ? 'form-control filled'
                                      : 'form-control'
                                  "
                                  :placeholder="$t('label.Type Here')"
                                  v-model="detail.address"
                                  v-on:placechanged="getAddressData"
                                  types="(cities)"
                                >
                                </vue-google-autocomplete>
                                <!-- <input type="text" class="form-control mb-2" />
                                <input type="text" class="form-control" /> -->
                              </div>
                            </div>
                          </div>
                          <div class="text-center mt-3">
                            <button id="submit-button" class="btn btn-brand-1 mb-3 px-5">
                              {{ $t("label.Save") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </form>
                </div>

                <div
                  class="tab-pane fade"
                  id="perating_area"
                  role="tabpanel"
                  aria-labelledby="perating_area-tab"
                >
                  <div class="form-style floating-form" v-if="addMore == '1'">
                    <div class="row justify-content-center mb-3">
                      <div class="col-md-12 d-flex flex-row">
                        <div class="floating-form-group with-icon">
                          <vue-google-autocomplete
                            ref="address2"
                            name="1234"
                            id="address2"
                            class="form-control filled"
                            v-model="address1"
                            :placeholder="$t('label.Type Here')"
                            v-on:placechanged="getOperatingWorkAddressData"
                            types=""
                          >
                          </vue-google-autocomplete>

                          <label for="operating_area">{{
                            $t("label.Operating Area")
                          }}</label>
                          <i class="bx bx-map icon"></i>
                          <span class="text-danger">*</span>
                        </div>

                        <button
                          v-on:click="addToOperatingAreaList()"
                          class="btn"
                          style="
                            padding-top: 11px;
                            color: #00bb59;
                            border-radius: 0px 5px 5px 0px !important;
                          "
                        >
                          {{ $t("label.Add") }}
                        </button>
                        <ErrorMessage name="operating_area" class="validation-msg" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 plr-zero">
                    <div class="text-right mb-2">
                      <a
                        v-on:click="showAddMore()"
                        class="btn btn-brand-1 btn-sm"
                        style="cursor: pointer"
                        v-if="addMore == '0'"
                      >
                        +
                        {{ $t("label.Add More") }}
                      </a>
                    </div>
                    <div class="">
                      <table
                        class="table table-style-1 table-responsive"
                        id="sorting_tbl"
                      >
                        <thead>
                          <tr>
                            <th>{{ $t("label.Operating Area") }}</th>
                            <th style="min-width: 150px">{{ $t("label.Action") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in areaList" :key="index">
                            <td>{{ data.area }}</td>
                            <td>
                              <button
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-secondary"
                                data-toggle="tooltip"
                                data-placement="top"
                                data-original-title="View"
                                v-on:click="showArea(data)"
                              >
                                <i class="bx bx-show"></i>
                              </button>
                              <button
                                class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                data-original-title="Delete"
                                v-on:click="showDeletePopup(data.id)"
                              >
                                <i class="bx bxs-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="images"
                  role="tabpanel"
                  aria-labelledby="images-tab"
                >
                  <!-- <div class="col-md-3 align-self-center">
                                        <button type="button" class="btn px-3 upload-img-merge-btn  btn-secondary font-24 border border-secondary" style="height:35px">
                                            <i class='bx bx-plus' ></i>
                                            <input type="file" class="btn upload-img" id="AddPhoto">
                                        </button> 
                                        </div> -->
                  <div class="text-right mb-3">
                    <button
                      type="button"
                      class="btn btn-brand-1 btn-sm"
                      v-on:click="$refs.textModal.showModal()"
                    >
                      <i class="bx bx-plus"></i> {{ $t("label.Add Image") }}
                    </button>
                  </div>

                  <table class="table table-style-1 table-responsive no-footer">
                    <thead>
                      <tr>
                        <th class="bg-white">{{ $t("label.images") }}</th>
                        <th class="bg-white" width="90">{{ $t("label.Action") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in company_detail.images" :key="index">
                        <td>
                          <img
                            :src="data.image ? data.image : 'placeholder-image.svg'"
                            class="object-cover"
                            height="60"
                            width="120"
                          />
                        </td>
                        <td>
                          <button
                            class="btn btn-action mr-2 mb-md-0 mb-2 text-danger"
                            v-on:click="showDeleteImagePopup(data.id)"
                          >
                            <i class="bx bxs-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- upload image -->
  <TextModal ref="textModal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="UploadImageLabel">
          {{ $t("label.Upload Image") }}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click="$refs.textModal.closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form @submit="saveImage()">
        <div class="modal-body">
          <div class="custom-select-img">
            <div class="file-upload">
              <div class="image-upload-wrap p-3 mb-4">
                <input
                  type="file"
                  name="img[]"
                  id="pickFile"
                  class="file-upload-input"
                  v-on:change="selectedFile($event, 'imageObj')"
                  accept="image/*"
                />
                <div class="drag-text text-center">
                  <img
                    id="preview2"
                    :src="
                      imageObj.image && imageObj.image != null
                        ? imageObj.image
                        : '/images/Imageplace-holder.svg'
                    "
                    width="80"
                    height="80"
                  />
                  <h3 class="heading font-20" for="pickFile">
                    {{ $t("label.Upload a image") }}
                  </h3>
                  <p class="font-12 text-secondary mb-1">
                    PNG, GIF, JPEG. {{ $t("label.(Max 10 mb)") }}
                  </p>
                  <p class="font-12 text-secondary mb-0">
                    {{ $t("label.(Optional)") }}
                  </p>
                </div>
              </div>
              <div class="file-upload-content">
                <img class="file-upload-image mb-3" src="#" alt="your image" />
                <div class="image-title-wrap">
                  <button type="button" onclick="removeUpload()" class="remove-image">
                    <i class="bx bx-x mr-2" aria-hidden="true"></i>
                    <span class="image-title">{{ $t("label.change Image") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-danger btn-action btn-brand-2"
            data-dismiss="modal"
            v-on:click="$refs.textModal.closeModal()"
          >
            {{ $t("label.Close") }}
          </button>
          <button id="save-button" class="btn btn-brand-1">
            {{ $t("label.Save") }}
          </button>
        </div>
      </Form>
    </div>
  </TextModal>
  <DeleteModal @remove="deleteImage" ref="deleteImgModal" />
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>

<script>
import TextModal from "@/components/TextModal";
import DeleteModal from "@/components/DeleteModal";
import $ from "jquery";
import { Form, Field, ErrorMessage } from "vee-validate";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import PhoneCode from "../../components/PhoneCodePicker/PhoneCode.vue";
export default {
  name: "MyProfile",
  components: {
    TextModal,
    DeleteModal,
    Form,
    Field,
    ErrorMessage,
    VueGoogleAutocomplete,
    PhoneCode,
  },

  data() {
    return {
      id: this.$route.params.id,
      countryList: [],
      file: undefined,
      imageObj: {},
      file2: undefined,
      detail: {
        address: "",
        latitude: "5.8976",
        longitude: "7.9887",
        dial_code: "+966",
        country_code: "sa",
        about: "",
      },
      areaList: [],
      address1: "",
      company_detail: {},
      area: "",
      latitude: "",
      longitude: "",
      addMore: "0",
    };
  },
  mounted() {
    this.$refs.address.focus();
    this.getCompanyDetail();
    this.meCall();
  },
  methods: {
    showDeleteImagePopup(id) {
      this.$refs.deleteImgModal.showModal(
        this.$t("label.Delete Event"),
        this.$t("label.Are you sure you want to delete"),
        id
      );
    },
    deleteImage(id) {
      this.$api
        .deleteAPI({
          _action: "company/" + this.company_detail.id + "/image/" + id,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.deleteImgModal.closeModal();
          this.getCompanyDetail();
        })
        .catch(() => {});
    },

    saveImage() {
      this.$api
        .uploadImageAPI({
          _action: "company/" + this.company_detail.id + "/image",
          _key: "image",
          _file: this.file2,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.file2 = undefined;
          this.imageObj.image = null;
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getCompanyDetail();
        });
    },
    getCompanyDetail() {
      this.$api
        .getAPI({
          _action: "company",
        })
        .then((res) => {
          this.company_detail = res.info;
        })
        .catch(() => {});
    },
    getAddressData: function (addressData) {
      this.detail.address = addressData.locality;
      this.detail.latitude = addressData.latitude;
      this.detail.longitude = addressData.longitude;
    },
    showArea(data) {
      this.$store.commit("setArea", data);
      this.$router.push("/area");
    },
    meCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.detail = res.info;
          this.detail.address = res.info.address;
          this.detail.about = res.info.company.about;
          this.$store.commit("setUser", this.detail);
          var $this = this;
          setTimeout(() => {
            document.getElementById("map").value = $this.detail.address;
          }, 500);
        })
        .catch(() => {});
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        this.$t("label.Delete Event"),
        this.$t("label.Are you sure you want to delete this address"),
        id
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "operating/area/" + id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getOperatingArea();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    selectedFile(e, type) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert(this.$t("label.Invalid file formate, please use jpeg or png file."));
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert(this.$t("label.File must be less then 10 MB"));
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      if (type == "profile") {
        this.file = files[0];
        document.getElementById("preview").src = tmppath;
      }
      if (type == "imageObj") {
        this.file2 = files[0];
        this.imageObj.image = tmppath;
        document.getElementById("preview2").src = tmppath;
      }
    },

    update() {
      this.$api
        .putAPI({
          _action: "set/profile",
          _body: this.detail,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            if (this.file) {
              this.uploadImage();
            } else {
              this.$store.commit("setUser", this.detail);
              this.meCall();
            }
          }
        })
        .catch(() => {});
    },
    uploadImage() {
      if (!this.file) {
        this.$router.go(-1);
      }
      var obj = {};
      obj.id = this.detail.id;
      this.$api
        .uploadImageAPI({
          _action: "set/profile/image",
          _key: "image",
          _file: this.file,
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          // this.detail.image = res.path;

          this.$store.commit("setUser", this.detail);
          this.meCall();
        });
    },
    readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#img_hoder_1").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    getOperatingArea() {
      this.addMore = "0";
      this.$api
        .getAPI({
          _action: "operating/areas",
        })
        .then((res) => {
          this.areaList = res.list;
          var $this = this;
          setTimeout(() => {
            document.getElementById("map").value = $this.detail.address;
          }, 500);
        })
        .catch(() => {});
    },

    showAddMore() {
      this.addMore = "1";
    },
    addToOperatingAreaList() {
      if (!this.area || this.area.length <= 0 || !this.latitude || !this.longitude) {
        this.$notify({
          type: "error",
          text: this.$t("label.Please enter operating area"),
        });
        return;
      }

      this.$api
        .postAPI({
          _action: "/operating/area",
          _body: {
            area: this.area,
            latitude: this.latitude,
            longitude: this.longitude,
          },
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.area = "";
            this.latitude = "";
            this.longitude = "";
            this.addMore = "0";
            this.getOperatingArea();
          }
        })
        .catch(() => {});
    },
    getOperatingWorkAddressData: function (addressData, placeResultData) {
      this.area = placeResultData.formatted_address;
      this.latitude = addressData.latitude;
      this.longitude = addressData.longitude;
    },
  },
};
</script>

